import Estilo from "./estilo.module.css";
import { CoresGlobal } from "../../../estilos";

var codigo_interno = 0

function ItenscardDashboard({titulo, Itens}) {
    return(
        <div className={Estilo.Item_Card} style={{'--teste': CoresGlobal.cor_botao_padrao}}>
            <h4>{titulo}</h4>

            {Itens ? 
                Object.keys(Itens).map((keyName, i) => {
                    codigo_interno = codigo_interno + 1
                    return(
                        <p key={codigo_interno}><span>{keyName}: </span>{Itens[keyName]}</p>
                    )
                })
                : null
            }                     
        </div>
    )
}

export default ItenscardDashboard