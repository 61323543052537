import React, { Component } from "react";
import EstiloPadrao from "./estilo.module.css";
import { CoresGlobal } from "../../../estilos";

class TituloComponentPadrao extends Component {
    constructor(props){
        super(props)

        //Este state é necessário para o getDerivedStateFromProps
        this.state ={
            state_titulo: '',
            state_estilo: undefined,
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            state_titulo: props.titulo,
            state_estilo: props.estilo,
        }
    } 

    render(){
        return(
            <div
                className={EstiloPadrao.ContainerInput} 
                style={Object.assign({}, {'width': '100%', background: CoresGlobal.cor_botao_padrao, border: `solid 0.1em ${CoresGlobal.cor_paleta_6}` }, this.state.state_estilo)}
            >
                <div className={EstiloPadrao.ContainerConteudo}>                  
                    {
                        (this.state.state_titulo !== '') ?
                        <div 
                            className={EstiloPadrao.tituloInput} 
                            style={{backgroundColor: CoresGlobal.cor_botao_padrao}}
                        >
                            {this.state.state_titulo}
                        </div>
                        : null
                    }
                </div>
            </div>
        );
    }
};

export default TituloComponentPadrao;