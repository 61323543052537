import React, { Component } from "react";
import BarraLateral from "../../../componentes/BarraLateral";
import InputPadrao from "../../../componentes/Inputs/ImputPadrao";
import BotaoPadrao from "../../../componentes/Botoes/BotaoPadrao";
import TituloPagina from "../../../componentes/TituloPagina";
import MsgPopUpPadrao from "../../../componentes/PopUpModal/MsgPopUpPadrao";
import TabelaPadrao from "../../../componentes/Tabelas/TabelaPadrao";
import Paginacao from "../../../componentes/Tabelas/Paginacao";
import Rodape from "../../../componentes/Rodape";
import withRouter from "../../../Rotas/rotasParametros";
import Api from '../../../servicos/Axios'
import PopUpLoadPadrao from '../../../componentes/PopUpLoad/PopUpLoadPadrao'
import Estilos from "./estilo.module.css";
import { Global_Retorna_Permissoes } from "../../../Funcoes_Globais"

//<Link to={"/clientes/cadastro"}  state={{ testea: "value" }}>Teste com parâmetro</Link>

class UsuariosConsulta extends Component{
    constructor(props){
        super(props)

        this.state = {
            State_MsgPopUpPadrao_Visivel: false,
            State_MsgPopUpPadrao_Mensagem: '',
            State_MsgPopUpPadrao_Tipo: 1,
            State_MsgPopUpPadrao_Acao: '',
            State_MsgPopUpPadrao_Mostrar_Botoes: true,

            State_LoadPopUpPadrao_Visivel: false,
            State_LoadPopUpPadrao_Mensagem: '',

            State_Permissao_Editar: Global_Retorna_Permissoes('usuario', 'editar'),
            State_Permissao_ver: Global_Retorna_Permissoes('usuario', 'ver'),
            State_Permissao_cadastrar: Global_Retorna_Permissoes('usuario', 'cadastrar'),

            State_Pesq_Valor: '',
            //lista_clientes: [
            //    {id: 1, nome: 'Fernando'},
            //    {id: 2, nome: 'Teste 1'},
            //    {id: 3, nome: 'Teste 2'},                                                                                                                      
            //],
            State_lista_usuarios: [], 
            
            State_Paginacao_Primeira_Consulta: true,
            State_Paginacao_total: 9,
            State_Paginacao_Limit: 9,
            State_Paginacao_Offset: 1,            
        }

        this.Editar_Usuario = this.Editar_Usuario.bind(this);
        this.Ver_Usuario = this.Ver_Usuario.bind(this);
        this.Pesquisar_Usuario = this.Pesquisar_Usuario.bind(this);

    };

    componentDidMount(){
        this.Pesquisar_Usuario();
    }

    Editar_Usuario(usuario_id){
        this.props.navegar("/usuarios/cadastro", { state: { usuario_id: usuario_id, status_operacao: 'editar' } });
    }  
    
    Ver_Usuario(usuario_id){
        this.props.navegar("/usuarios/cadastro", { state: { usuario_id: usuario_id, status_operacao: 'ver' } });
    }      

    Adicionar_Usuario(){
       this.props.navegar("/usuarios/cadastro", { state: { usuario_id: 0, status_operacao: 'cadastrar' } });
    }

    Abre_MsgPopUpPadrao(Tipo = 1, Mostrar_botoes = true, Mensagem = '', Acao_Botao = '', Tempo_fechar = 0){
        this.setState({
            State_MsgPopUpPadrao_Visivel: true,
            State_MsgPopUpPadrao_Tipo: Tipo,
            State_MsgPopUpPadrao_Mensagem: Mensagem,
            State_MsgPopUpPadrao_Acao: Acao_Botao, 
            State_MsgPopUpPadrao_Mostrar_Botoes: Mostrar_botoes,  
        }, () => {
            if (Tempo_fechar > 0){
                setTimeout(
                    this.Fecha_MsgPopUpPadrao.bind(this, 'timer'),
                    Tempo_fechar
                );                
            };
        });
    };

    Fecha_MsgPopUpPadrao(Tipo_botao){
        this.setState({
            State_MsgPopUpPadrao_Visivel: false,               
        }, () => {
            if ((Tipo_botao === 'ok') || (Tipo_botao === 'sim') || (Tipo_botao === 'timer')){
                if (this.state.State_MsgPopUpPadrao_Acao === 'Teste'){
                    //console.log('Roda a Função Teste');
                };
            };
        });
    }; 
    
    Abre_LoadPopUpPadrao(mensagem){
        this.setState({
            State_LoadPopUpPadrao_Visivel: true,
            State_LoadPopUpPadrao_Mensagem: mensagem,            
        });
    };

    Fecha_LoadPopUpPadrao(){
        this.setState({
            State_LoadPopUpPadrao_Visivel: false,
            State_LoadPopUpPadrao_Mensagem: '',            
        });
    };    

    Pesquisar_Usuario(Offset = 0){
        /*
            O State Pesq_Pagi_Primeira_Consulta Precisa ser alterado no onchange do imput da consulta
            Se o retorno for  dois, jogar o state State_Paginacao_total: 9, State_Paginacao_Limit: 9, State_Paginacao_Offset: 0
        */
        this.setState({
            State_Paginacao_Offset: Offset,
            State_lista_usuarios: [],
        });

        var objJson = { 
            Pesq_Valor: this.state.State_Pesq_Valor,
            Pesq_Pagi_Offset: Offset,
            Pesq_Pagi_Limit: this.state.State_Paginacao_Limit,
            Pesq_Pagi_Primeira_Consulta: this.state.State_Paginacao_Primeira_Consulta, 
        }   

        this.Abre_LoadPopUpPadrao('Por favor aguarde ...');
        Api.Executa_chamada(
            this.props.navegar, 'Usuario_Consulta', 0, JSON.stringify(objJson)
        ).then((resposta) => {
            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                this.setState({
                    State_lista_usuarios: resposta.dados.usuario,
                }, function(){
                    if(this.state.State_Paginacao_Primeira_Consulta){
                        this.setState({
                            State_Paginacao_total: resposta.dados.qtd_paginas_calculadas, 
                            State_Paginacao_Primeira_Consulta: false,                            
                        });
                    };
                    //console.log('Total>', this.state.State_Paginacao_total);
                });
            }else if (resposta.status === 2){
                this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)  
                this.setState({
                    State_Paginacao_total: 9,
                    State_Paginacao_Limit: 9,
                    State_Paginacao_Offset: 1,
                });                        
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });
    }    

    render(){
        return(
        <div>         
            <MsgPopUpPadrao
                visivel = {this.state.State_MsgPopUpPadrao_Visivel}
                mensagem = {this.state.State_MsgPopUpPadrao_Mensagem}
                tipo = {this.state.State_MsgPopUpPadrao_Tipo}
                mostrar_botoes = {this.state.State_MsgPopUpPadrao_Mostrar_Botoes}
                onclick_btn_ok = {this.Fecha_MsgPopUpPadrao.bind(this, 'ok')}
                onclick_btn_sim = {this.Fecha_MsgPopUpPadrao.bind(this, 'sim')}
                onclick_btn_nao = {this.Fecha_MsgPopUpPadrao.bind(this, 'nao')}
            />
            <PopUpLoadPadrao
                visivel = {this.state.State_LoadPopUpPadrao_Visivel}
                mensagem = {this.state.State_LoadPopUpPadrao_Mensagem}
            />            
            <BarraLateral/>
            <div className="GlobalConteinerPrincipal"> 
                <div className="GlobalConteudoPrincipal">

                    <TituloPagina titulo={"Usuarios"} />
                    <div className={Estilos.ConteinerPesquisa}>                    
                        {
                            this.state.State_Permissao_cadastrar ?
                            <BotaoPadrao
                                tipo={"adicionar"}
                                descricao={"Novo"}
                                onClick={() =>{this.Adicionar_Usuario()}}
                            />
                            : null                            
                        }
                    </div>                    
                    <div className={Estilos.ConteinerPesquisa}>
                        <InputPadrao
                            //titulo={'Pesquisar'}
                            dica = {'Nome, Email'}
                            value = {this.state.State_Pesq_Valor}
                            tipo = {'pesquisa'}
                            onClick={() =>{this.Pesquisar_Usuario()}}                    
                            onChange = {valor => this.setState({State_Pesq_Valor: valor.target.value, State_Paginacao_Primeira_Consulta:true})}
                            onKeyUp={event => {if (event.key === 'Enter') {this.Pesquisar_Usuario()}}}
                            //autoFocus = {true}
                        />
                    </div>
                    <div>
                    <TabelaPadrao
                        estilo = {{marginTop: '0px'}}
                        //titulo={''}
                        Itens = {this.state.State_lista_usuarios}
                        motrarBtnEditar={this.state.State_Permissao_Editar}
                        motrarBtnVisualizar={this.state.State_Permissao_ver}
                        motrarBtnExcluir = {false}
                        clickEditar={this.Editar_Usuario}
                        clickVisualizar={this.Ver_Usuario}
                    />                         
                    <Paginacao 
                        limit={this.state.State_Paginacao_Limit}
                        total={this.state.State_Paginacao_total}
                        offset={this.state.State_Paginacao_Offset}
                        setOffset={this.Pesquisar_Usuario}                        
                    />                                           
                    </div>

                </div>
            </div>
            <Rodape/>
        </div>            
        );
    };
};

export default withRouter(UsuariosConsulta);