import React, { Component } from "react";
import EstiloPadrao from "./estilo.module.css";
import { FaSearch } from "react-icons/fa";
import { CoresGlobal } from "../../../estilos";

class InputPesquisa extends Component {
    constructor(props){
        super(props)

        //Este state é necessário para o getDerivedStateFromProps
        this.state ={
            state_titulo: '',
            state_dica: '',
            state_estilo: '',
            state_onChange: '' ,
            state_onKeyUp: undefined,
            state_tipo: undefined,
            state_value: '',
            state_disabled: '',
            state_maxLength: undefined
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            state_titulo: props.titulo,
            state_dica: props.dica,
            state_estilo: props.estilo,
            state_onChange: props.onChange,
            state_onClick: props.onClick,
            state_value: props.value,
            state_disabled: props.disabled,
            state_onKeyUp: props.onKeyUp,                  
            state_tipo: props.tipo,
            state_maxLength: props.maxLength
        }
    } 

    render(){
        return(
            <div
                className={EstiloPadrao.ContainerInput} 
                style={{'width': '100%', background: CoresGlobal.cor_botao_padrao, border: `solid 0.1em ${CoresGlobal.cor_paleta_6}` }} 
            >
                <div className={EstiloPadrao.ContainerConteudo}>                  
                    {
                        (this.state.state_titulo !== '') ?
                        <div 
                            className={EstiloPadrao.tituloInput} 
                            style={{backgroundColor: CoresGlobal.cor_botao_padrao}}
                        >
                            {this.state.state_titulo}
                        </div>
                        : null
                    }
                    <div style={{display: 'flex'}}>
                        {
                            this.state.state_tipo === 'pesquisa' ?
                            <div className={EstiloPadrao.Alinha_Icon} style={{padding: '0 .5em'}}>
                                <FaSearch 
                                    size={20} 
                                    color="white"
                                    onClick={this.state.state_onClick}
                                />
                            </div>
                            : null
                        }
                        <input
                            disabled = {this.state.state_disabled}        
                            className={EstiloPadrao.inputPadrao} 
                            type="text"
                            placeholder={this.state.state_dica}
                            onChange={this.state.state_onChange} 
                            value={this.state.state_value}
                            onKeyUp={this.props.onKeyUp}
                            autoFocus = {this.props.autoFocus}
                            maxLength={this.props.maxLength}
                        >   
                        </input>                                                
                    </div>
                </div>
            </div>
        );
    }
};

export default InputPesquisa;