import React from 'react';
import Estilo from './estilo.module.css';
import { CoresGlobal } from '../../../estilos';

const MAX_ITEMS = 9;
const MAX_LEFT = (MAX_ITEMS - 1) / 2;

const Paginacao = ({
  limit,
  total,
  offset,
  setOffset
}) => {
  const current = offset ? offset / limit + 1 : 1;
  const pages = Math.ceil(total / limit);
  const maxFirst = Math.max(pages - (MAX_ITEMS - 1), 1);
  const first = Math.min(
    Math.max(current - MAX_LEFT, 1),
    maxFirst
  );

  function onPageChange(page) {
    setOffset((page - 1) * limit);
  }

  return (
    <ul 
        style={{
            '--cor_botao_padrao': CoresGlobal.cor_botao_padrao,
            '--cor_paleta_4': CoresGlobal.cor_paleta_4
        }}
        className={Estilo.pagination}
    >    
      {Array.from({ length: Math.min(MAX_ITEMS, pages) })
        .map((_, index) => index + first)
        .map((page) => (
          <li key={page}>
            <button
              onClick={() => onPageChange(page)}
              className={
                page === current
                  ? Estilo.pagination_item_active
                  : Estilo.pagination_item
              }
            >
              {page}
            </button>
          </li>
        ))}
        <li>
          <button
              className={Estilo.pagination_item_proximo}
              onClick={() => onPageChange(current - 1)}
              disabled={current === 1}
          >
            {'<'}
          </button>
        </li>         
        <li>
          <button
              className={Estilo.pagination_item_proximo}
              onClick={() => onPageChange(current + 1)}
              disabled={current === pages}
          >
            {'>'}
          </button>
        </li> 
    </ul>
  );
};

export default Paginacao;