import React, { Component } from "react";
import BarraLateral from "../../../componentes/BarraLateral";
import InputPadrao from "../../../componentes/Inputs/ImputPadrao";
import BotaoPadrao from "../../../componentes/Botoes/BotaoPadrao";
//import InputDataPadrao from "../../../componentes/Inputs/InputDataPadrao";
//import TooglePadrao from "../../../componentes/Botoes/TooglePadrao";
import TituloPagina from "../../../componentes/TituloPagina";
import TituloCadastroDetalhe from "../../../componentes/Titulos/TituloCadatrosDetalhe";
import MsgPopUpPadrao from "../../../componentes/PopUpModal/MsgPopUpPadrao";
//import TabelaPadrao from "../../../componentes/Tabelas/TabelaPadrao";
//import ComboBoxPadrao from "../../../componentes/ComboBox/ComboboxPadrão"
import Rodape from "../../../componentes/Rodape";
import { Global_Valida_Data, Global_Retorna_Permissoes } from "../../../Funcoes_Globais";
import withRouter from "../../../Rotas/rotasParametros";
import Api from '../../../servicos/Axios';
import Estilos from "./estilo.module.css";
import PopUpLoadPadrao from '../../../componentes/PopUpLoad/PopUpLoadPadrao';



//https://www.freecodecamp.org/news/jwt-authentication-in-flask/

class RevendasCadastro extends Component{    
    constructor(props){
        super(props)

        this.state = {
            State_MsgPopUpPadrao_Visivel: false,
            State_MsgPopUpPadrao_Mensagem: '',
            State_MsgPopUpPadrao_Tipo: 1,
            State_MsgPopUpPadrao_Acao: '',
            State_MsgPopUpPadrao_Mostrar_Botoes: true,

            State_LoadPopUpPadrao_Visivel: false,
            State_LoadPopUpPadrao_Mensagem: '',
            
            State_Permissao_Editar: Global_Retorna_Permissoes('revenda', 'editar'),
            State_Permissao_Ver: Global_Retorna_Permissoes('revenda', 'ver'),
            State_Permissao_cadastrar: Global_Retorna_Permissoes('revenda', 'cadastrar'),                        

            state_lista_revendas: [],

            state_status_operacao: this.props.match.paramsLocation.state.status_operacao ,
            state_revenda_id: this.props.match.paramsLocation.state.revenda_id,            
            state_revenda_cnpj: '',            
            state_revenda_razao: '',
            state_revenda_cod_identificacao: '',
        };
        
        //https://stackoverflow.com/questions/70143135/how-to-use-react-router-dom-v6-navigate-in-class-component
        //O Match pega o parâmetro passado de outra tela pelo link, o navegar navega entre as telas
        //console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>");
        //console.log(this.props.match)         
        //console.log(this.props.match.paramsLocation.state.cliente_id);      
        //console.log(this.props.match.paramsLocation.state.status_operacao);
        //console.log(this.state.state_cliente_id)

    };

    componentDidMount (){
        this.Funcoes_Iniciais()                  
    };

    Funcoes_Iniciais = async () =>{
        if (this.state.state_status_operacao === 'editar' || this.state.state_status_operacao === 'ver') {
            this.Pesquisar_Revenda();
        }              
    }    

    Abre_MsgPopUpPadrao(Tipo = 1, Mostrar_botoes = true, Mensagem = '', Acao_Botao = '', Tempo_fechar = 0){
        this.setState({
            State_MsgPopUpPadrao_Visivel: true,
            State_MsgPopUpPadrao_Tipo: Tipo,
            State_MsgPopUpPadrao_Mensagem: Mensagem,
            State_MsgPopUpPadrao_Acao: Acao_Botao, 
            State_MsgPopUpPadrao_Mostrar_Botoes: Mostrar_botoes,  
        }, () => {
            if (Tempo_fechar > 0){
                setTimeout(
                    this.Fecha_MsgPopUpPadrao.bind(this, 'timer'),
                    Tempo_fechar
                );                
            };
        });
    };

    Fecha_MsgPopUpPadrao(Tipo_botao){
        this.setState({
            State_MsgPopUpPadrao_Visivel: false,               
        }, () => {
            if ((Tipo_botao === 'ok') || (Tipo_botao === 'sim') || (Tipo_botao === 'timer')){
                if (this.state.State_MsgPopUpPadrao_Acao === 'Excluir_Terminal'){
                    //this.Excluir_Terminal(this.state.state_excluir_terminal_codigo);
                };
            };
        });
    }; 
    
    Abre_LoadPopUpPadrao(mensagem){
        this.setState({
            State_LoadPopUpPadrao_Visivel: true,
            State_LoadPopUpPadrao_Mensagem: mensagem,            
        });
    };

    Fecha_LoadPopUpPadrao(){
        this.setState({
            State_LoadPopUpPadrao_Visivel: false,
            State_LoadPopUpPadrao_Mensagem: '',            
        });
    };     

    Pesquisar_Revenda(){
        var objJson = { 
            Pesq_Codigo: this.state.state_revenda_id,
        };
        
        this.Abre_LoadPopUpPadrao('Por favor aguarde ...');
        
        Api.Executa_chamada(
            this.props.navegar, 'Revenda_Detalhe', 0, JSON.stringify(objJson)
        ).then((resposta) => {

            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                this.setState({
                    state_revenda_cnpj: resposta.dados.revenda.cnpj,                    
                    state_revenda_razao: resposta.dados.revenda.razao,
                    state_revenda_cod_identificacao: resposta.dados.revenda.cod_identificacao,
                }, function (){
                    //
                });
            }else if (resposta.status === 2){
                this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)           
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });        
    };

    Salva_Revenda(){
        //if (!Global_Valida_Data(this.state.state_cliente_validade_licenca)) {
        //    this.Abre_MsgPopUpPadrao(5, false, 'Validade da licença Inválida!', '', 1500)
        //    return;            
        //};

        var objJson = { 
            revenda_id: this.state.state_revenda_id,
            revenda_cnpj: this.state.state_revenda_cnpj,
            revenda_razao: this.state.state_revenda_razao,

        };        

        this.Abre_LoadPopUpPadrao('Salvando informações  ...');
        Api.Executa_chamada(
            this.props.navegar, 'Revenda_Atu_Cadastra', 0, JSON.stringify(objJson)
        ).then((resposta) => {
            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                if (this.state.state_status_operacao === 'cadastrar'){
                    this.setState({
                        state_revenda_id: resposta.dados.revenda_id,
                        state_status_operacao: 'editar',
                    }, ()=>{
                        this.Pesquisar_Revenda();
                        this.Abre_MsgPopUpPadrao(1, false, 'Revenda cadastrada com sucesso!', '', 2000);
                    });
                }else{
                    this.Abre_MsgPopUpPadrao(1, false, 'Cadastro atualizado com sucesso!', '', 2000);
                }
            }else if (resposta.status === 2){
                this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)           
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });        
    }

    Atualiza_Validade_Licensa(data_str){
        if (Global_Valida_Data(data_str)){
            this.setState({state_cliente_validade_licenca: data_str});
        }
    };
        
    render(){
        return(
        <div>
            <MsgPopUpPadrao
                visivel = {this.state.State_MsgPopUpPadrao_Visivel}
                mensagem = {this.state.State_MsgPopUpPadrao_Mensagem}
                tipo = {this.state.State_MsgPopUpPadrao_Tipo}
                mostrar_botoes = {this.state.State_MsgPopUpPadrao_Mostrar_Botoes}
                onclick_btn_ok = {this.Fecha_MsgPopUpPadrao.bind(this, 'ok')}
                onclick_btn_sim = {this.Fecha_MsgPopUpPadrao.bind(this, 'sim')}
                onclick_btn_nao = {this.Fecha_MsgPopUpPadrao.bind(this, 'nao')}
            />
            <PopUpLoadPadrao
                visivel = {this.state.State_LoadPopUpPadrao_Visivel}
                mensagem = {this.state.State_LoadPopUpPadrao_Mensagem}
            />                                 
            <BarraLateral/>
            <div className="GlobalConteinerPrincipal"> 
                <div className="GlobalConteudoPrincipal">

                    <TituloPagina titulo={"Revenda > Cadastro"} />
                    <TituloCadastroDetalhe titulo={"Geral"} />                 
                    <div className={Estilos.ContainerGrid}>
                        <InputPadrao
                                titulo={'Razão'}
                                onChange = {valor => this.setState({state_revenda_razao: valor.target.value})}
                                value = {this.state.state_revenda_razao}    
                        />
                        <InputPadrao 
                                titulo={'Cnpj'}
                                onChange = {valor => this.setState({state_revenda_cnpj: valor.target.value})}
                                value = {this.state.state_revenda_cnpj}    
                        />

                        <InputPadrao 
                                titulo={'Identificação'}
                                disabled = {true}
                                onChange = {valor => this.setState({state_revenda_cod_identificacao: valor.target.value})}
                                value = {this.state.state_revenda_cod_identificacao}    
                        />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
                    </div>                                                    
               
                    <div className={Estilos.ConteinerBotoes} >
                        <BotaoPadrao 
                            tipo={"voltar"} 
                            descricao={"Voltar"} 
                            onClick={() =>{this.props.navegar(-1)}} 
                        />

                        {
                            ((this.state.State_Permissao_Editar || this.state.State_Permissao_cadastrar) && (this.state.state_status_operacao === 'editar' || this.state.state_status_operacao === 'cadastrar')) ?
                            <BotaoPadrao
                                tipo={"salvar"} 
                                descricao={"Salvar"} 
                                onClick={() =>{this.Salva_Revenda()}} 
                            />
                            : null
                        }                 
                    </div>                                                                                                   
                </div>
            </div>
            <Rodape/>
        </div>            
        );
    };
};

export default withRouter(RevendasCadastro);