import Estilo from "./estilo.module.css"

function Container(props) {
    return(
        <div className={Estilo.ContainerGrid} style={props.estilo}>
            {props.children}
        </div>
    )
}

export default Container