import React, { Component } from "react";
import BarraLateral from "../../../componentes/BarraLateral";
import InputPadrao from "../../../componentes/Inputs/ImputPadrao";
import BotaoPadrao from "../../../componentes/Botoes/BotaoPadrao";
import InputDataPadrao from "../../../componentes/Inputs/InputDataPadrao";
import TooglePadrao from "../../../componentes/Botoes/TooglePadrao";
import TituloPagina from "../../../componentes/TituloPagina";
import TituloCadastroDetalhe from "../../../componentes/Titulos/TituloCadatrosDetalhe";
import MsgPopUpPadrao from "../../../componentes/PopUpModal/MsgPopUpPadrao";
import TabelaTipo1 from "../../../componentes/Tabelas/TabelaPadrao/ItemTabelaPadrao";
import TabelaPadrao from "../../../componentes/Tabelas/TabelaPadrao";
import ComboBoxPadrao from "../../../componentes/ComboBox/ComboboxPadrão"
import Rodape from "../../../componentes/Rodape";
import { Global_Converte_Data, Global_Valida_Data, Global_Retorna_Permissoes, Global_Retorna_Usuario } from "../../../Funcoes_Globais";
import withRouter from "../../../Rotas/rotasParametros";
import Api from '../../../servicos/Axios';
import Estilos from "./estilo.module.css";
import PopUpLoadPadrao from '../../../componentes/PopUpLoad/PopUpLoadPadrao';



//https://www.freecodecamp.org/news/jwt-authentication-in-flask/

class ClientesCadastro extends Component{    
    constructor(props){
        super(props)

        this.state = {
            State_MsgPopUpPadrao_Visivel: false,
            State_MsgPopUpPadrao_Mensagem: '',
            State_MsgPopUpPadrao_Tipo: 1,
            State_MsgPopUpPadrao_Acao: '',
            State_MsgPopUpPadrao_Mostrar_Botoes: true,

            State_LoadPopUpPadrao_Visivel: false,
            State_LoadPopUpPadrao_Mensagem: '',
            
            State_Permissao_Editar: Global_Retorna_Permissoes('cliente', 'editar'),
            State_Permissao_Ver: Global_Retorna_Permissoes('cliente', 'ver'),
            State_Permissao_cadastrar: Global_Retorna_Permissoes('cliente', 'cadastrar'),
            State_Login_Revenda: Global_Retorna_Usuario('login_revenda'),

            state_lista_revendas: [],

            state_status_operacao: this.props.match.paramsLocation.state.status_operacao ,
            state_cliente_id: this.props.match.paramsLocation.state.cliente_id,            
            state_cliente_cnpj: '',            
            state_cliente_razao: '',
            state_cliente_endereco: '',
            state_cliente_cidade: '',
            state_cliente_uf: '',
            state_cliente_cep: '',
            state_cliente_bairro: '',
            state_cliente_email: '',
            state_cliente_fone: '',
            state_cliente_nterminais: '',
            state_cliente_validade_licenca: '',
            state_cliente_bloqueado: 'S',
            state_clientes_cad_terminais: [],
            state_lista_terminais: [] ,
            state_clientes_cod_revenda: 0,
            state_excluir_terminal_codigo: 0,
            teste: '',
        };

        this.Pergunta_Excluir_Terminal = this.Pergunta_Excluir_Terminal.bind(this);
        this.Escolhe_Revenda = this.Escolhe_Revenda.bind(this);
        this.Retorna_Listar_Diversas = this.Retorna_Listar_Diversas.bind(this);
        
        //https://stackoverflow.com/questions/70143135/how-to-use-react-router-dom-v6-navigate-in-class-component
        //O Match pega o parâmetro passado de outra tela pelo link, o navegar navega entre as telas
        //console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>");
        //console.log(this.props.match)         
        //console.log(this.props.match.paramsLocation.state.cliente_id);      
        //console.log(this.props.match.paramsLocation.state.status_operacao);
        //console.log(this.state.state_cliente_id)

    };

    componentDidMount (){
        this.Funcoes_Iniciais()                  
    };

    Funcoes_Iniciais = async () =>{
        //Precisa esperar o retorno da função Retorna_Listar_Diversas
        await this.Retorna_Listar_Diversas();
        if (this.state.state_status_operacao === 'editar' || this.state.state_status_operacao === 'ver') {
            this.Pesquisar_Cliente();
        }
    }    

    Abre_MsgPopUpPadrao(Tipo = 1, Mostrar_botoes = true, Mensagem = '', Acao_Botao = '', Tempo_fechar = 0){
        this.setState({
            State_MsgPopUpPadrao_Visivel: true,
            State_MsgPopUpPadrao_Tipo: Tipo,
            State_MsgPopUpPadrao_Mensagem: Mensagem,
            State_MsgPopUpPadrao_Acao: Acao_Botao, 
            State_MsgPopUpPadrao_Mostrar_Botoes: Mostrar_botoes,  
        }, () => {
            if (Tempo_fechar > 0){
                setTimeout(
                    this.Fecha_MsgPopUpPadrao.bind(this, 'timer'),
                    Tempo_fechar
                );                
            };
        });
    };

    Fecha_MsgPopUpPadrao(Tipo_botao){
        this.setState({
            State_MsgPopUpPadrao_Visivel: false,               
        }, () => {
            if ((Tipo_botao === 'ok') || (Tipo_botao === 'sim') || (Tipo_botao === 'timer')){
                if (this.state.State_MsgPopUpPadrao_Acao === 'Excluir_Terminal'){
                    this.Excluir_Terminal(this.state.state_excluir_terminal_codigo);
                };
            };
        });
    }; 
    
    Abre_LoadPopUpPadrao(mensagem){
        this.setState({
            State_LoadPopUpPadrao_Visivel: true,
            State_LoadPopUpPadrao_Mensagem: mensagem,            
        });
    };

    Fecha_LoadPopUpPadrao(){
        this.setState({
            State_LoadPopUpPadrao_Visivel: false,
            State_LoadPopUpPadrao_Mensagem: '',            
        });
    };    
    
    Retorna_Listar_Diversas(){
        return new Promise((resolve) => {
            var objJson = { 
                revenda: true,
                permissao: true,   
            };
    
            this.Abre_LoadPopUpPadrao('Por favor aguarde ...');
            Api.Executa_chamada(
                this.props.navegar, 'Retorna_Listas_Diversas', 0, JSON.stringify(objJson)
            ).then((resposta) => {
                resolve(true);
                this.Fecha_LoadPopUpPadrao();
                if (resposta.status === 1){
                    //console.log(resposta.dados.revendas)
                    this.setState({
                        state_lista_revendas: resposta.dados.revendas,
                        state_lista_permissoes: resposta.dados.permissoes,
                    }, function (){
                        if ((resposta.dados.revendas) && (this.state.State_Login_Revenda) && (this.state.state_status_operacao === 'cadastrar')){
                            this.setState({
                                state_clientes_cod_revenda: resposta.dados.revendas[0].codigo
                            })
                        }
                    });
                }else if (resposta.status === 2){
                    this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)           
                }
                else{
                    this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
                };           
            });            
        });
    };  

    Pesquisar_Cliente(){
        var objJson = { 
            Pesq_Codigo: this.state.state_cliente_id,
        };
        
        this.Abre_LoadPopUpPadrao('Por favor aguarde ...');
        
        Api.Executa_chamada(
            this.props.navegar, 'Cliente_Detalhe', 0, JSON.stringify(objJson)
        ).then((resposta) => {

            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                this.setState({
                    state_cliente_cnpj: resposta.dados.cliente.cnpj,                    
                    state_cliente_razao: resposta.dados.cliente.razao,
                    state_cliente_endereco: resposta.dados.cliente.endereco,
                    state_cliente_cidade: resposta.dados.cliente.cidade,
                    state_cliente_uf: resposta.dados.cliente.uf,
                    state_cliente_cep: resposta.dados.cliente.cep,
                    state_cliente_bairro: resposta.dados.cliente.bairro,
                    state_cliente_email: resposta.dados.cliente.email,
                    state_cliente_fone: resposta.dados.cliente.fone,
                    state_cliente_nterminais: resposta.dados.cliente.nterminais,
                    state_cliente_validade_licenca: Global_Converte_Data(resposta.dados.cliente.validade_licenca),
                    state_cliente_bloqueado: resposta.dados.cliente.bloqueado,                  
                    state_lista_terminais: resposta.dados.terminais,
                    state_clientes_cod_revenda: resposta.dados.cliente.cod_revenda,
                }, function (){
                    //
                });
            }else if (resposta.status === 2){
                this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)           
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });        
    };

    Salva_Cliente(){
        //valida os campos
        if (this.state.state_cliente_cnpj.trim() === ''){
            this.Abre_MsgPopUpPadrao(3, false, 'Cnpj/cpf inválido!', '', 1000);
            return
        }
        if (this.state.state_cliente_razao.trim() === ''){
            this.Abre_MsgPopUpPadrao(3, false, 'Razão inválida!', '', 1000);
            return
        }
        if (this.state.state_cliente_cidade.trim() === ''){
            this.Abre_MsgPopUpPadrao(3, false, 'Cidade inválida!', '', 1000);
            return
        }
        if (this.state.state_cliente_uf.trim() === ''){
            this.Abre_MsgPopUpPadrao(3, false, 'UF/Estado Inválido!', '', 1000);
            return
        }
        if (this.state.state_cliente_cep.trim() === ''){
            this.Abre_MsgPopUpPadrao(3, false, 'CEP Inválido!', '', 1000);
            return
        }
        if (this.state.state_cliente_bairro.trim() === ''){
            this.Abre_MsgPopUpPadrao(3, false, 'Bairro Inválido!', '', 1000);
            return
        }
        if (this.state.state_cliente_endereco.trim() === ''){
            this.Abre_MsgPopUpPadrao(3, false, 'Endereço inválido!', '', 1000);
            return
        }
        if (this.state.state_clientes_cod_revenda === 0){
            this.Abre_MsgPopUpPadrao(3, false, 'Revenda Inválida!', '', 1000);
            return
        }

        var objJson = { 
            cliente_id: this.state.state_cliente_id,
            cliente_bloqueado: this.state.state_cliente_bloqueado,
            cliente_cnpj: this.state.state_cliente_cnpj,
            cliente_razao: this.state.state_cliente_razao,
            cliente_endereco: this.state.state_cliente_endereco,
            cliente_cidade: this.state.state_cliente_cidade,
            cliente_uf: this.state.state_cliente_uf,
            cliente_cep: this.state.state_cliente_cep,
            cliente_bairro: this.state.state_cliente_bairro,
            cliente_email: this.state.state_cliente_email,
            cliente_fone: this.state.state_cliente_fone,
            cliente_nterminais: this.state.state_cliente_nterminais,
            cliente_validade_licenca: this.state.state_cliente_validade_licenca,
            clientes_cod_revenda: this.state.state_clientes_cod_revenda,
        };        

        this.Abre_LoadPopUpPadrao('Salvando informações  ...');
        Api.Executa_chamada(
            this.props.navegar, 'Cliente_Atu_Cadastra', 0, JSON.stringify(objJson)
        ).then((resposta) => {
            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                if (this.state.state_status_operacao === 'cadastrar'){
                    this.setState({
                        state_cliente_id: resposta.dados.cliente_id,
                        state_status_operacao: 'editar',
                    }, ()=>{
                        this.Pesquisar_Cliente();
                        this.Abre_MsgPopUpPadrao(1, false, 'Cliente cadastrado com sucesso!', '', 1500);
                    });
                }else{
                    this.Abre_MsgPopUpPadrao(1, false, 'Cadastro atualizado com sucesso!', '', 1500);
                }
            }else if (resposta.status === 2){
                this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 1500)           
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 1500)
            };           
        });        
    }

    Atualiza_Validade_Licensa(data_str){
        if (Global_Valida_Data(data_str)){
            this.setState({state_cliente_validade_licenca: data_str});
        }
    };

    Pergunta_Excluir_Terminal(codigo){
        this.setState({
            state_excluir_terminal_codigo: codigo 
        }, () => {
            this.Abre_MsgPopUpPadrao(2, true, 'Tem certeza que gostaria de excluir este terminal?', 'Excluir_Terminal', 0)            
        });
    };

    Excluir_Terminal(codigo){
        var objJson = { 
            terminal_id: codigo,
        };
        
        this.Abre_LoadPopUpPadrao('Por favor aguarde ...');
        Api.Executa_chamada(
            this.props.navegar, 'Cliente_Del_Terminal', 0, JSON.stringify(objJson)
        ).then((resposta) => {
            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                const newList = this.state.state_lista_terminais.filter((item) => item.codigo !== codigo);
                this.setState({
                    state_lista_terminais: newList,
                }, function (){
                    this.Abre_MsgPopUpPadrao(1, false, 'Terminal excluído com sucesso!', '', 1000) 
                });                
            }else if (resposta.status === 2){
                this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)           
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });               
    };

    Renderiza_Itens_Terminais = (codigo, fk_empresa, mac, nome_computador) => {
        var objJson = { 
            MAC: mac,
        };

        return(
            <TabelaTipo1
                id = {codigo}
                titulo = {nome_computador}
                Itens={objJson}
                onClick={() =>{this.Excluir_Terminal(codigo)}}
            />
        )
    };

    Escolhe_Revenda(e){
        e.preventDefault();
        this.setState({
            state_clientes_cod_revenda: e.target.value
        });
    }
        
    render(){
        return(
        <div>
            <MsgPopUpPadrao
                visivel = {this.state.State_MsgPopUpPadrao_Visivel}
                mensagem = {this.state.State_MsgPopUpPadrao_Mensagem}
                tipo = {this.state.State_MsgPopUpPadrao_Tipo}
                mostrar_botoes = {this.state.State_MsgPopUpPadrao_Mostrar_Botoes}
                onclick_btn_ok = {this.Fecha_MsgPopUpPadrao.bind(this, 'ok')}
                onclick_btn_sim = {this.Fecha_MsgPopUpPadrao.bind(this, 'sim')}
                onclick_btn_nao = {this.Fecha_MsgPopUpPadrao.bind(this, 'nao')}
            />
            <PopUpLoadPadrao
                visivel = {this.state.State_LoadPopUpPadrao_Visivel}
                mensagem = {this.state.State_LoadPopUpPadrao_Mensagem}
            />                                 
            <BarraLateral/>
            <div className="GlobalConteinerPrincipal"> 
                <div className="GlobalConteudoPrincipal">

                    <TituloPagina titulo={"Clientes > Cadastro"} />
                    <TituloCadastroDetalhe titulo={"Geral"} />                 
                    <div className={Estilos.ContainerGrid}>
                        <InputPadrao 
                                titulo={'Código'}
                                value = {this.state.state_cliente_id}   
                                disabled = {true}
                        />  

                        <InputPadrao 
                                titulo={'*Cnpj/Cpf'}
                                onChange = {valor => this.setState({state_cliente_cnpj: valor.target.value})}
                                value = {this.state.state_cliente_cnpj}
                                maxLength = {20}   
                        />  
                        <InputPadrao 
                                titulo={'*Razão'}
                                onChange = {valor => this.setState({state_cliente_razao: valor.target.value})}
                                value = {this.state.state_cliente_razao}
                                maxLength = {50}   
                        />                                                                                                                                                                                                                    

                        <InputPadrao 
                                titulo={'*Cidade'}
                                onChange = {valor => this.setState({state_cliente_cidade: valor.target.value})}
                                value = {this.state.state_cliente_cidade}
                                maxLength = {35}    
                        />  

                        <InputPadrao 
                                titulo={'*UF'}
                                onChange = {valor => this.setState({state_cliente_uf: valor.target.value})}
                                value = {this.state.state_cliente_uf}
                                maxLength = {2}
                        /> 

                        <InputPadrao 
                                titulo={'*Cep'}
                                onChange = {valor => this.setState({state_cliente_cep: valor.target.value})}
                                value = {this.state.state_cliente_cep}
                                maxLength = {8}    
                        />
                        <InputPadrao 
                                titulo={'*Bairro'}
                                onChange = {valor => this.setState({state_cliente_bairro: valor.target.value})}
                                value = {this.state.state_cliente_bairro}
                                maxLength = {35}    
                        />

                        <InputPadrao 
                                titulo={'*Endereço'}
                                onChange = {valor => this.setState({state_cliente_endereco: valor.target.value})}
                                value = {this.state.state_cliente_endereco} 
                                maxLength = {50}   
                        />                        

                        <InputPadrao 
                                titulo={'Email'}
                                onChange = {valor => this.setState({state_cliente_email: valor.target.value})}
                                value = {this.state.state_cliente_email}
                                maxLength = {120}    
                        />  

                        <InputPadrao 
                                titulo={'Fone'}
                                onChange = {valor => this.setState({state_cliente_fone: valor.target.value})}
                                value = {this.state.state_cliente_fone} 
                                maxLength = {20}   
                        />
                        {
                            this.state.State_Login_Revenda === false ?
                            <ComboBoxPadrao
                                titulo = {'*Revenda'}
                                dica = {'Escolha a Revenda'}
                                itens = {this.state.state_lista_revendas}
                                onChange = {this.Escolhe_Revenda}
                                select_value = {this.state.state_clientes_cod_revenda}
                            />
                            : null
                        }
                    </div>
                    <TituloCadastroDetalhe titulo={"Licença"} />
                    <div className={Estilos.ContainerGrid}>
                        <InputDataPadrao 
                            titulo = 'Validade da Licença'
                            value={this.state.state_cliente_validade_licenca}
                            onChange={valor => this.Atualiza_Validade_Licensa(valor.target.value)}    
                        />                        

                        <TooglePadrao
                            descricao={'Bloqueado'}
                            marcado={this.state.state_cliente_bloqueado}
                            onClick={() => this.setState({state_cliente_bloqueado: (this.state.state_cliente_bloqueado === 'S') ? 'N' : 'S'})}
                        />
                    </div>                     
                    <TituloCadastroDetalhe titulo={"Terminais"} />                    
                    <div className={Estilos.ContainerGrid}>
                        <InputPadrao 
                                    titulo={'Limite de Terminais'}
                                    onChange = {valor => this.setState({state_cliente_nterminais: valor.target.value})}
                                    value = {this.state.state_cliente_nterminais}    
                        />
                    </div>                  
                    {
                        <TabelaPadrao
                            estilo = {{marginTop: '0px', height: '13em'}}
                            estilo2 = {{marginTop: '5px'}}
                            titulo={
                                (this.state.state_lista_terminais.length > 0 ) ?
                                `${this.state.state_lista_terminais.length} Terminais Cadastrados`
                                :
                                "Nenhum Terminal Cadastrado"
                            }
                            Itens = {this.state.state_lista_terminais}
                            motrarBtnExcluir = {(this.state.State_Permissao_Editar && this.state.state_status_operacao === 'editar')}
                            clickExcluir ={this.Pergunta_Excluir_Terminal}
                        />
                    }                
                    <div className={Estilos.ConteinerBotoes} >
                        <BotaoPadrao 
                            tipo={"voltar"} 
                            descricao={"Voltar"} 
                            onClick={() =>{this.props.navegar(-1)}} 
                        />

                        {
                            ((this.state.State_Permissao_Editar || this.state.State_Permissao_cadastrar) && (this.state.state_status_operacao === 'editar' || this.state.state_status_operacao === 'cadastrar')) ?
                            <BotaoPadrao
                                tipo={"salvar"} 
                                descricao={"Salvar"} 
                                onClick={() =>{this.Salva_Cliente()}} 
                            />
                            : null
                        }                 
                    </div>                                                                                                   
                </div>
            </div>
            <Rodape/>
        </div>            
        );
    };
};

export default withRouter(ClientesCadastro);