import { useState, useEffect, useCallback} from "react";
import BarraLateral from "../../componentes/BarraLateral";
import TituloPagina from "../../componentes/TituloPagina";
import Rodape from "../../componentes/Rodape";
//import Estilo from "./estilo.module.css";
import Container from "../../componentes/Container";
import Api from '../../servicos/Axios';
import ItenscardDashboard from "../../componentes/Tabelas/ItensCardDashboard";

function Dashboard() {
    const [stateItens, setItens] =useState([])

    const Pesquisar_Cliente = useCallback(() =>{
        //this.setState({
        //    State_Paginacao_Offset: Offset,
        //    State_lista_clientes: []
        //});

        var objJson = { 
            Pesq_Valor: '',
        }

        //this.Abre_LoadPopUpPadrao('Por favor aguarde ...');
        Api.Executa_chamada(
            undefined, 'Dashboard', 0, JSON.stringify(objJson)
        ).then((resposta) => {
            //this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                setItens(resposta.dados.informacoes)
                //console.log(stateItens)
            }else if (resposta.status === 2){
                //this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)                         
            }
            else{
                //this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });
    }, [])     

    useEffect(() =>{
        Pesquisar_Cliente()  
    }, [Pesquisar_Cliente])  

    return(
        <div>
            <BarraLateral/>          
            <div className="GlobalConteinerPrincipal">
                <div className="GlobalConteudoPrincipal">
                    <TituloPagina titulo={"Dashboard"} />
                    <Container estilo = {{"align-items": "flex-start", "min-height": "75%"}}>
                        {
                            stateItens.length > 0 &&
                            stateItens.map((item) =>(
                            <ItenscardDashboard
                                key={item.id}
                                titulo = {item.descricao}
                                Itens = {item.Itens}
                            />                                 
                            ))
                        }                                            
                    </Container>
                </div>
            </div>
            <Rodape/>
        </div>
    );
}

export default Dashboard;