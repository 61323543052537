import React from "react";
import BarraLateral from "../../componentes/BarraLateral";
import Estilo from "./estilo.module.css";
import Imagem from "../../imagens/MsgPopUpPadrao/imgCuidado.png";

function AcessoNegado() {
    return(
        <div>
            <BarraLateral/>          
            <div className="GlobalConteinerPrincipal">
                <div className="GlobalConteudoPrincipal">
                    <div className={Estilo.Conteudo}>
                        <img src={Imagem} alt="" ></img>
                        <p className={Estilo.Mensagem}>
                            Você não tem permissão de acesso a esta página!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AcessoNegado;