import ItemTabelaPadrao from "../TabelaPadrao/ItemTabelaPadrao";
import { CoresGlobal } from "../../../estilos";
import Estilo from "./estilo.module.css";
import React from "react";
//import { MdHeight } from "react-icons/md";

//const TabelaTipo1 = ({id, titulo, Itens, onClick}) =>{
function TabelaPadrao ({estilo, estilo2, titulo, Itens, clickEditar, clickVisualizar, clickExcluir, 
    motrarBtnEditar, motrarBtnVisualizar, motrarBtnExcluir}) {        
    return(
        <div 
            style={Object.assign({},{width: '100%', border: `solid 0.1em ${CoresGlobal.cor_paleta_6}`, marginTop: '0em'}, estilo2)}
        >
            {
                (titulo ? 
                <div className={Estilo.TabelaTitulo} style={{backgroundColor: CoresGlobal.cor_botao_padrao}}>
                    {titulo}
                </div>
                : null)
            }                
            <div 
                style={Object.assign({},{height: '20em', overflowY: 'scroll',}, estilo)}
            >
                <div>
                    {
                    Itens.map((item) =>{
                        return(                                
                            <ItemTabelaPadrao
                                key = {item.codigo}
                                titulo = {item.descricao}
                                Itens={item.Itens}
                                motrarBtnEditar = {motrarBtnEditar}
                                motrarBtnVisualizar = {motrarBtnVisualizar}
                                motrarBtnExcluir = {motrarBtnExcluir}
                                clickEditar={() =>{clickEditar(item.codigo)}}
                                clickVisualizar={() =>{clickVisualizar(item.codigo)}}
                                clickExcluir={() =>{clickExcluir(item.codigo)}}
                            />
                        )                            
                    })
                    }                                                
                </div>
            </div>
        </div>
    );
};

export default TabelaPadrao;