export const Global_Valida_Data = (data_str) =>{
    var data = new Date(data_str);
    var validado = false;
    if (!isNaN(data))
        validado = true;

    if (data_str === null)
        validado = false;

    if (data.getFullYear() > 3000)
        validado = false;

    return validado;
};

export const Global_Converte_Data = (data) =>{
    var data_convertida = new Date(data).toLocaleDateString('en-CA');

    if (!data){
        data_convertida = null;
    }else{
        data_convertida = new Date(data).toISOString().slice(0, 10);
    };  

    return data_convertida;
};

export const Global_Async_Local_Storage = {
    setItem(campo, dados) {
        return localStorage.setItem(campo, dados);
    },
    getItem(campo) {
        return localStorage.getItem(campo);
    },
    clear() {
        return localStorage.clear();
    }    
};

export const Global_Retorna_Usuario = (item) =>{
    var lista_permissoes = JSON.parse(Global_Async_Local_Storage.getItem('dados_usuario')) ? JSON.parse(Global_Async_Local_Storage.getItem('dados_usuario')) : [];
    var item_retorno = undefined

    //console.log('>>>', lista_permissoes)
    //console.log('>>>', lista_permissoes.login_revenda)

    if (item === 'login_revenda' && lista_permissoes.login_revenda !== undefined)
        item_retorno = lista_permissoes.login_revenda    

    return item_retorno
};

export const Global_Retorna_Permissoes = (rotina, item) =>{
    var lista_permissoes = JSON.parse(Global_Async_Local_Storage.getItem('dados_permissoes')) ? JSON.parse(Global_Async_Local_Storage.getItem('dados_permissoes')) : [];
    var permissao = false;
    var json = {};
    var json_ver = 'N';
    var json_editar = 'N';
    var json_excluir = 'N';
    var json_cadastrar = 'N';

    json = lista_permissoes.find(element => element.rotina === rotina);
    
    if (!json){
        return permissao;
    };

    json_ver = lista_permissoes.find(element => element.rotina === rotina).ver
    json_editar = lista_permissoes.find(element => element.rotina === rotina).editar;
    json_excluir = lista_permissoes.find(element => element.rotina === rotina).excluir;
    json_cadastrar = lista_permissoes.find(element => element.rotina === rotina).cadastrar;

    if (item === 'ver' && json_ver === 'S')
        permissao = true
    else if(item === 'editar' && json_editar === 'S')
        permissao = true
    else if(item === 'excluir' && json_excluir === 'S')
        permissao = true
    else if(item === 'cadastrar' && json_cadastrar === 'S')
        permissao = true    
    else
        permissao = false;

    return permissao;
};

export const Global_Retorna_SubMenu = (rotina) =>{
    var lista_permissoes = JSON.parse(Global_Async_Local_Storage.getItem('dados_permissoes')) ? JSON.parse(Global_Async_Local_Storage.getItem('dados_permissoes')) : [];
    var permissao = false;
    var json = {};

    json = lista_permissoes.find(element => element.rotina === rotina);
    
    if (!json){
        return permissao;
    };

    return permissao;
};