import React, {useState} from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as RiIcons from "react-icons/ri";
import { IconContext } from "react-icons";
import {BarraLateralDados2} from "./Dados2";
import Submenu from "./submenu";
import { Global_Async_Local_Storage } from "../../Funcoes_Globais";
import Logo from "../../imagens/Logo/Logo_login_3.png";
import Estilo from "./estilo.module.css";
import { CoresGlobal } from "../../../src/estilos/";

const Nav = styled.div`
    background: #0b418c;
    height: 50px;
    display: Flex;
    justify-content: flex-start;
    align-itens: center;
`;
const NavIcon = styled(Link)`
    margin-left: 0.5rem;
    font-size: 2rem;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;
const NavIconLogOut = styled(Link)`
    margin-right: 0.1rem;
    font-size: 2rem;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;
const SidbarNav = styled.nav`
    background: ${CoresGlobal.cor_paleta_1};
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: ${({ statemostrasidebar }) => (statemostrasidebar ? '0' : '-100%')};    
    transition: 350ms;
    z-index: 10;
`;
const SidbarWrap = styled.div`
    width: 100%;
    overflow: auto;
`;

const BarraLateral = () => {
    const [statemostrasidebar, setSidbar] = useState(false);
    var codigo_interno = 0

    function showSidbar(){
        setSidbar(!statemostrasidebar);
    };

    function deslogar(){
        /*
            Essa questão de desligar e não deixar entrar na tela sem login está no arquivo src/Rotas/rotas.jsx 
        */  
        Global_Async_Local_Storage.clear();
    }     

    return(
        <>
            <IconContext.Provider value={{color: '#fff'}}>
                <Nav>
                    <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                        <NavIcon to = '#'>
                            <FaIcons.FaBars onClick={showSidbar}/>
                        </NavIcon>
                        <img src={Logo} alt="" ></img>
                        <NavIconLogOut to = '/Login'>
                            <RiIcons.RiLogoutBoxRFill onClick={deslogar}/>
                        </NavIconLogOut>  
                    </div>                  
                </Nav>
                <SidbarNav statemostrasidebar = {statemostrasidebar}>
                    <SidbarWrap className={Estilo.EscondeScrollBar}>
                        <NavIcon to = '#'>
                            <AiIcons.AiOutlineClose onClick={showSidbar}/>
                        </NavIcon>
                        {BarraLateralDados2().map((item, index) => {
                            codigo_interno = codigo_interno + 1
                            return <Submenu key={codigo_interno} itemSubmenu={item} indexSubmenu={item.key}/>
                        })}                    
                    </SidbarWrap>
                </SidbarNav>
            </IconContext.Provider>
        </>
    );
};

export default BarraLateral;