import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { CoresGlobal } from "../../../src/estilos/";

const SidebarLink = styled(Link)`
    display: flex;
    color: #e1e9fc;
    justify-content: space-between;
    align-items: center;
    /*padding: 20px;*/
    list-style: none;
    height: 2.8em;
    padding-left: 0.5rem;
    text-decoration: none;
    font-size: 1em;

    &:hover{
        background: #252831;
        border-left: 4px solid #632ce4;
        cursor: pointer;        
    }    
`;
const SidebarLabel = styled.span`
    margin-left: 10px;
`;
const DropDowLink = styled(Link)`
    background: ${CoresGlobal.cor_botao_padrao};
    height: 2.8em;
    padding-left: 1.5rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #f5f5f5;
    font-size: 1em;

    &:hover{
        background: ${CoresGlobal.cor_paleta_7};
        cursor: pointer;
    }    
`;
const Submenu = ({itemSubmenu, indexSubmenu}) => {
    const [stateSubMenu, setStateSubMenu] = useState(false);
    const MostraSubMenu = () =>setStateSubMenu(!stateSubMenu);    
    return(
        <>
            {
                itemSubmenu ?
                <SidebarLink key={indexSubmenu} to={itemSubmenu.caminho} onClick={itemSubmenu.subMenu && MostraSubMenu}>
                    <div>
                        {itemSubmenu.icone}
                        <SidebarLabel>{itemSubmenu.titulo}</SidebarLabel>
                    </div>
                    <div>
                        {itemSubmenu.subMenu && stateSubMenu 
                        ? itemSubmenu.iconeAberto 
                        : itemSubmenu.subMenu 
                        ? itemSubmenu.iconeFechado 
                        : null}
                    </div>    
                </SidebarLink>
                : null
            }
            {stateSubMenu && itemSubmenu.subMenu.map((itemSubmenu2, index) =>{
            return( 
                <DropDowLink to={itemSubmenu2.caminho} key={index}>
                    {itemSubmenu2.icone}
                    <SidebarLabel>{itemSubmenu2.titulo}</SidebarLabel>
                </DropDowLink>
            );
            })}
        </>
    );
};

export default Submenu;