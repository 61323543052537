import { Component } from 'react';
import axios_import from 'axios';
import { Global_Async_Local_Storage } from "../../Funcoes_Globais"

class ConfiguraAxios extends Component {
	constructor(props) {
        super(props)
        this.ip_servidor = '';
        this.exec = '';
        this.axios_import = '';
		this.configura_axios();
	};

    configura_axios = () =>{
        //Servidor
        //this.url = 'http://192.168.2.100:5000/';
        this.url = 'https://controle.adeptaerp.com.br/api/';
		this.axios_import = axios_import;
        this.exec = this.axios_import.create({
            baseURL: this.url
        });       
    };  
    
    Executa_chamada = async(navegar, rota, espera_timeout, valores_enviados) =>{
        /*
            Os códigos de retorno serão:
            1 = OK, obteve resposta da api
            2 = Erro no lado do cliente
            3 = Erro no lado do servidor

            Sobre os códigos de retorno padrão da api
            https://restfulapi.net/http-status-codes/
        */
        var respostaJson = {status: 0, msg: '', dados: {}, api_status: 0}; 
        var respostaJsonRefreshToken = {status: 0, msg: '', dados: {}, api_status: 0};

        respostaJson = await this.Chamada_Post(navegar, rota, espera_timeout, valores_enviados);

        if ((respostaJson.api_status === 401) && (respostaJson.msg === 'Token has expired')){
            //console.log('>>>>>>>>>>Renova Token')
            respostaJsonRefreshToken = await this.Renova_Token();

            if (respostaJsonRefreshToken.api_status === 200){
                //console.log('>>>>>>>>>>Requisição do refresh token deu certo') ;
                Global_Async_Local_Storage.setItem("access_token", respostaJsonRefreshToken.dados.usuario_access_token); 
                respostaJson = await this.Executa_chamada(navegar, rota, espera_timeout, valores_enviados);
            }else if ((respostaJsonRefreshToken.api_status === 401) && (respostaJson.msg === 'Token has expired')){
                //console.log('3>>>>>>>>>>Refresth token expirado, precisa fazer o login novamente');
                Global_Async_Local_Storage.setItem("access_token", '');
                if (navegar){
                    navegar("/login");
                }                
            }
        }if ((respostaJson.api_status === 450) && (respostaJson.msg === 'Acesso Negado')){
            if (navegar){
                navegar("/load_inicial");
            }   
        }

        return respostaJson;
    };

    Chamada_Post = (navegar, rota, espera_timeout, valores_enviados) =>{
        var respostaJson = {status: 0, msg: '', dados: {}, api_status: 0}; 
        var acess_token = Global_Async_Local_Storage.getItem('access_token')

        let rota_api = rota;
        let json_envio = valores_enviados;

        var tempo_espera = 5000;
        if (espera_timeout > 0){
            tempo_espera = espera_timeout;
        };

        let source = axios_import.CancelToken.source();
        setTimeout(() => {
            source.cancel();
          }, tempo_espera);        
        
        return this.exec.post(rota_api, {json_envio}, {
            cancelToken: source.token, 
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',  // <-- here
                'AUTHORIZATION': `Bearer ${acess_token}`
            },           
        })
        .then((response) => {
            this.api_cod_resposta = response.status;
            if ((response.status >= 200) && (response.status <= 299)){            
                //console.log('Resposta 200')
                //console.log(response.data)
                respostaJson.msg = '';
                respostaJson.dados = response.data;
                respostaJson.status = 1;
                respostaJson.api_status = response.status;
                return respostaJson;
            }
          }, (error) => {
            if (axios_import.isCancel(error)) {
                //console.log("Sem conexão com o servidor, verifique sua internet!");
                respostaJson.msg = "Sem conexão com o servidor, verifique sua internet!";                   
                respostaJson.status = 3;
                if(error.response.status){
                    respostaJson.api_status = error.response.status;
                }
            }else{
                if (error === 'Error: Network Error'){
                    //console.log("Sem conexão com o Servidor!");                    
                    respostaJson.msg = 'Sem conexão com o Servidor!';              
                    respostaJson.status = 3;
                    if(error.response.status){
                        respostaJson.api_status = error.response.status;
                    }                   
    
                }else if (!error.response){                
                    //console.log("Conexão estabelecida, porém nada foi retornado")
                    respostaJson.msg = 'Conexão estabelecida, porém nada foi retornado'; 
                    respostaJson.status = 3;                                          
                }else if ((error.response.status >= 500) && (error.response.status <= 599)){
                    //console.log("Erro no lado do servidor");
                    respostaJson.msg = error.response.data['msg'];
                    if (!error.response.data['msg']){
                        respostaJson.msg = '';
                    }                     
                    respostaJson.status = 3;  
                    if(error.response.status){
                        respostaJson.api_status = error.response.status;
                    }               
                }else if ((error.response.status >= 400) && (error.response.status <= 499)){
                    //console.log("Erro no lado do cliente");
                    respostaJson.msg = error.response.data['msg'];
                    if (!error.response.data['msg']){
                        respostaJson.msg = '';
                    }    
                    respostaJson.status = 2;
                    if(error.response.status){
                        respostaJson.api_status = error.response.status;
                    }              
                }else{
                    //console.log("Erro desconhecido na conexão");
                    //console.log(error);
                    respostaJson.status = 3;
                    respostaJson.msg = 'Erro desconhecido > ' + error;
                    if(error.response.status){
                        respostaJson.api_status = error.response.status;
                    }                 
                }                
            }
            return respostaJson;
        }); 

    };
    
    Renova_Token = () =>{
        var respostaJson = {status: 0, msg: '', dados: {}, api_status: 0};
        var usuario_refresh_token = Global_Async_Local_Storage.getItem('refresh_token');

        let source = axios_import.CancelToken.source();
        setTimeout(() => {
            source.cancel();
          }, 5000);        

        return this.exec.post('Refresh_Token', {}, {cancelToken: source.token, headers: {'Authorization': `Bearer ${usuario_refresh_token}`}})
        .then((response) => {
            this.api_cod_resposta = response.status;
            if ((response.status >= 200) && (response.status <= 299)){            
                //console.log('Resposta 200')
                respostaJson.msg = '';
                respostaJson.dados = response.data;
                respostaJson.status = 1;
                respostaJson.api_status = response.status;
                return respostaJson;
            }
          }, (error) => {
            if (axios_import.isCancel(error)) {
                //console.log("Sem conexão com o servidor, verifique sua internet!");
                respostaJson.msg = "Sem conexão com o servidor, verifique sua internet!";                   
                respostaJson.status = 3;
                if(error.response.status){
                    respostaJson.api_status = error.response.status;
                }
            }else{
                if (error === 'Error: Network Error'){
                    //console.log("Sem conexão com o Servidor!");                    
                    respostaJson.msg = 'Sem conexão com o Servidor!';              
                    respostaJson.status = 3;
                    if(error.response.status){
                        respostaJson.api_status = error.response.status;
                    }                   
    
                }else if (!error.response){                
                    //console.log("Conexão estabelecida, porém nada foi retornado")
                    respostaJson.msg = 'Conexão estabelecida, porém nada foi retornado'; 
                    respostaJson.status = 3;                                          
                }else if ((error.response.status >= 500) && (error.response.status <= 599)){
                    console.log("Erro no lado do servidor");
                    respostaJson.msg = error.response.data['msg'];
                    if (!error.response.data['msg']){
                        respostaJson.msg = '';
                    }                     
                    respostaJson.status = 3;  
                    if(error.response.status){
                        respostaJson.api_status = error.response.status;
                    }               
                }else if ((error.response.status >= 400) && (error.response.status <= 499)){
                    //console.log("Erro no lado do cliente");
                    respostaJson.msg = error.response.data['msg'];
                    if (!error.response.data['msg']){
                        respostaJson.msg = '';
                    }    
                    respostaJson.status = 2;
                    if(error.response.status){
                        respostaJson.api_status = error.response.status;
                    }              
                }else{
                    //console.log("Erro desconhecido na conexão");
                    ////console.log(error);
                    respostaJson.status = 3;
                    respostaJson.msg = 'Erro desconhecido > ' + error;
                    if(error.response.status){
                        respostaJson.api_status = error.response.status;
                    }                 
                }                
            }
            return respostaJson;
        });        
    }
};

const axios = new ConfiguraAxios()
export default axios