import React, { Component } from "react";
import BarraLateral from "../../componentes/BarraLateral";
import TituloPagina from "../../componentes/TituloPagina";
import MsgPopUpPadrao from "../../componentes/PopUpModal/MsgPopUpPadrao";
import Rodape from "../../componentes/Rodape";
import withRouter from "../../Rotas/rotasParametros";
import Api from '../../servicos/Axios';
import PopUpLoadPadrao from '../../componentes/PopUpLoad/PopUpLoadPadrao';
import { Global_Async_Local_Storage } from "../../Funcoes_Globais";

//<Link to={"/clientes/cadastro"}  state={{ testea: "value" }}>Teste com parâmetro</Link>

class LoadInicial extends Component{
    constructor(props){
        super(props)

        this.state = {
            State_lista_clientes: [],            
        }
    };

    componentDidMount(){
        this.Abre_LoadPopUpPadrao('Por favor aguarde ...');
        this.Busca_Dados_Iniciais();
    }

    Abre_MsgPopUpPadrao(Tipo = 1, Mostrar_botoes = true, Mensagem = '', Acao_Botao = '', Tempo_fechar = 0){
        this.setState({
            State_MsgPopUpPadrao_Visivel: true,
            State_MsgPopUpPadrao_Tipo: Tipo,
            State_MsgPopUpPadrao_Mensagem: Mensagem,
            State_MsgPopUpPadrao_Acao: Acao_Botao, 
            State_MsgPopUpPadrao_Mostrar_Botoes: Mostrar_botoes,  
        }, () => {
            if (Tempo_fechar > 0){
                setTimeout(
                    this.Fecha_MsgPopUpPadrao.bind(this, 'timer'),
                    Tempo_fechar
                );                
            };
        });
    };

    Fecha_MsgPopUpPadrao(Tipo_botao){
        this.setState({
            State_MsgPopUpPadrao_Visivel: false,               
        }, () => {
            if ((Tipo_botao === 'ok') || (Tipo_botao === 'sim') || (Tipo_botao === 'timer')){
                if (this.state.State_MsgPopUpPadrao_Acao === 'Teste'){
                    //console.log('Roda a Função Teste');
                };
            };
        });
    };
    
    Abre_LoadPopUpPadrao(mensagem){
        this.setState({
            State_LoadPopUpPadrao_Visivel: true,
            State_LoadPopUpPadrao_Mensagem: mensagem,            
        });
    };

    Fecha_LoadPopUpPadrao(){
        this.setState({
            State_LoadPopUpPadrao_Visivel: false,
            State_LoadPopUpPadrao_Mensagem: '',            
        });
    };
    
    Busca_Dados_Iniciais(){
        var objJson = { 
            Pesq_Valor: '1',
        }        
        this.Abre_LoadPopUpPadrao('Por favor aguarde ...');

        Api.Executa_chamada(
            this.props.navegar, 'Usuario_Dados_Iniciais', 0, JSON.stringify(objJson), this.props.navegar
        ).then((resposta) => {
            this.Fecha_LoadPopUpPadrao();
            
            if (resposta.status === 1){
                //console.log(resposta.dados.dados_usuario)
                //console.log(resposta.dados.dados_permissoes)
                Global_Async_Local_Storage.setItem("dados_usuario", JSON.stringify(resposta.dados.dados_usuario));
                Global_Async_Local_Storage.setItem("dados_permissoes", JSON.stringify(resposta.dados.dados_permissoes));
                this.props.navegar("/dashboard")
            }else if (resposta.status === 2){
                this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)           
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });        
    }

    render(){
        return(
        <div>    
            <MsgPopUpPadrao
                visivel = {this.state.State_MsgPopUpPadrao_Visivel}
                mensagem = {this.state.State_MsgPopUpPadrao_Mensagem}
                tipo = {this.state.State_MsgPopUpPadrao_Tipo}
                mostrar_botoes = {this.state.State_MsgPopUpPadrao_Mostrar_Botoes}
                onclick_btn_ok = {this.Fecha_MsgPopUpPadrao.bind(this, 'ok')}
                onclick_btn_sim = {this.Fecha_MsgPopUpPadrao.bind(this, 'sim')}
                onclick_btn_nao = {this.Fecha_MsgPopUpPadrao.bind(this, 'nao')}
            />
            <PopUpLoadPadrao
                visivel = {this.state.State_LoadPopUpPadrao_Visivel}
                mensagem = {this.state.State_LoadPopUpPadrao_Mensagem}
            />
            <BarraLateral/>
            <div className="GlobalConteinerPrincipal"> 
                <div className="GlobalConteudoPrincipal">

                    <TituloPagina titulo={"Load inicial"} />                                

                </div>
            </div>
            <Rodape/>
        </div>            
        );
    };
};

export default withRouter(LoadInicial);