import EstiloPadrao from "./estilo.module.css";
import { CoresGlobal } from "../../../estilos"

const TituloCadastroDetalhe = ({titulo, estilo}) =>{  
    return(
        <div 
            className={EstiloPadrao.ContainerPrincipal}
            style={Object.assign({}, { '--cor_botao_padrao': CoresGlobal.cor_paleta_1}, estilo)}
        >
            <h2 className={EstiloPadrao.titulo}>{titulo}</h2>
        </div>
    );
};

export default TituloCadastroDetalhe;