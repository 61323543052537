import EstiloPadrao from "./estilo.module.css";
import { CoresGlobal } from "../../estilos"

const TituloPagina = ({titulo}) =>{  
    return(
        <div 
            className={EstiloPadrao.ContainerPrincipal}
            style={{'--cor_paleta_1': CoresGlobal.cor_paleta_1, '--cor_paleta_7': CoresGlobal.cor_paleta_7}}
        >
            <h2 className={EstiloPadrao.titulo}>{titulo}</h2>
        </div>
    );
};

export default TituloPagina;