import React, {useState, useEffect} from "react";
import {Routes, Route, useLocation } from "react-router-dom";
import { Global_Async_Local_Storage, Global_Retorna_Permissoes } from "../Funcoes_Globais"
//import Produtos1 from "../paginas/produtos/Produtos1";
//import Produtos2 from "../paginas/produtos/Produtos2";
import Login from "../paginas/Login";
import ClientesConsulta from "../paginas/clientes/consulta";
import ClientesCadastro from "../paginas/clientes/cadastro";
import UsuariosConsulta from "../paginas/usuarios/consulta";
import UsariosCadastro from "../paginas/usuarios/cadastro";
import LoadInicial from "../paginas/Load_Inicial";
import AcessoNegado from "../paginas/Acesso_Negado";
import Dashboard from "../paginas/Dash_Board";
import PermissoesConsulta from "../paginas/Configuracoes/Permissoes/consulta";
import PermissoesCadastro from "../paginas/Configuracoes/Permissoes/cadastro";
import RevendasConsulta from "../paginas/Revenda/consulta";
import RevendaCadastro from "../paginas/Revenda/cadastro";


function RotasPrincipais() {
    /*Esse useLocation faz disparar o useEffect sempre que uma rota é alterando, por exemplo do login para outra tela qualquer,
    então estou usando isso pra descobrir se tem o token de login, e liberar ou não as rotas para navegação*/
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [verCliente, setverCliente] = useState(true);
    const [verUsuario, setverUsuario] = useState(true);
    const [verPermissao, setverPermissao] = useState(true);
    const [verRevenda, setverRevenda] = useState(true);
    const location = useLocation();

    function Permissao_usuario(){
        setverCliente(Global_Retorna_Permissoes('cliente', 'ver'));
        setverUsuario(Global_Retorna_Permissoes('usuario', 'ver'));
        setverPermissao(Global_Retorna_Permissoes('config_perm', 'ver'));
        setverRevenda(Global_Retorna_Permissoes('revenda', 'ver'));
    }    
    
    useEffect(() => {
        (Global_Async_Local_Storage.getItem('access_token') === '') ? setIsLoggedIn(false) : setIsLoggedIn(true);

        Permissao_usuario();

    }, [isLoggedIn, verCliente, location]);

    const Rota_Protegida = (rota = '', tela, logado, visivel) =>{

        if (!logado)
            return(<Route path={rota} element={<Login/>}/>);
        if (!visivel)
            return(<Route path={rota} element={<AcessoNegado/>}/>);

        return(<Route path={rota} element={tela}/>);

    }

    //<Route path="/clientes/consulta" exact Component={ClientesConsulta}/>
    //<Route path="/clientes/consulta" element={(isLoggedIn && verCliente) ? <ClientesConsulta/> : <Login/>}/>
    /*
            <Route path="/login" exact Component={Login}/>
            <Route path="/clientes/cadastro" element={isLoggedIn ? <ClientesCadastro/> : <Login/>}/>
            <Route path="/usuarios/consulta" element={isLoggedIn ? <UsuariosConsulta/> : <Login/>}/>
            <Route path="/usuarios/cadastro" element={isLoggedIn ? <UsariosCadastro/> : <Login/>}/>            
            <Route path="/load_inicial" element={isLoggedIn ? <LoadInicial/> : <Login/>}/>                        
            <Route path="/produto/produto1" element={isLoggedIn ? <Produtos1/> : <Login/>}/>
            <Route path="/produto/produto2" element={isLoggedIn ? <Produtos2/> : <Login/>}/>    
    */
    return(
        <Routes path="/">
            <Route index element={<Login />} />
            <Route path="/login" exact Component={Login}/>
            {Rota_Protegida("/clientes/consulta", <ClientesConsulta/>, isLoggedIn, verCliente)}
            {Rota_Protegida("/clientes/cadastro", <ClientesCadastro/>, isLoggedIn, verCliente)}
            {Rota_Protegida("/usuarios/consulta", <UsuariosConsulta/>, isLoggedIn, verUsuario)}
            {Rota_Protegida("/usuarios/cadastro", <UsariosCadastro/>, isLoggedIn, verUsuario)}
            {Rota_Protegida("/load_inicial", <LoadInicial/>, isLoggedIn, true)}
            {Rota_Protegida("/dashboard", <Dashboard/>, isLoggedIn, true)}
            {Rota_Protegida("/configuracoes/permissoes/consulta", <PermissoesConsulta/>, isLoggedIn, verPermissao)}
            {Rota_Protegida("/configuracoes/permissoes/cadastro", <PermissoesCadastro/>, isLoggedIn, verPermissao)}
            {Rota_Protegida("/revendas/consulta", <RevendasConsulta/>, isLoggedIn, verRevenda)}
            {Rota_Protegida("/revendas/cadastro", <RevendaCadastro/>, isLoggedIn, verRevenda)}            
        </Routes>
    )
}

export default RotasPrincipais;