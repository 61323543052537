import { Global_Retorna_Permissoes } from "../../Funcoes_Globais";
import * as RiIcons from "react-icons/ri";
import * as BsIcons from "react-icons/bs";
import * as FaIcons from "react-icons/fa";
import * as PiIcons from "react-icons/pi";
import * as Fa6Icons from "react-icons/fa6";
//import * as TbIcons from "react-icons/tb";
import * as IoIcons from "react-icons/io";

var codigo_interno = 0

export const BarraLateralDados2 = () =>{
    //var lista_permissoes = JSON.parse(Global_Async_Local_Storage.getItem('dados_permissoes')) ? JSON.parse(Global_Async_Local_Storage.getItem('dados_permissoes')) : [];
    var json = [];
    var json_Submenu_Configuracoes = [];
    var objJson = {};

    codigo_interno = codigo_interno + 1
    objJson = {
        key: codigo_interno, 
        titulo: 'Dashboard',
        caminho: '/dashboard',
        icone: <RiIcons.RiDashboardHorizontalFill/>,   
    };
    json.push(objJson);    

    if (Global_Retorna_Permissoes('cliente', 'ver')){
        codigo_interno = codigo_interno + 1
        objJson = {
            key: codigo_interno,
            titulo: 'Clientes',
            caminho: '/clientes/consulta',
            icone: <BsIcons.BsPersonFill />,   
        };
        json.push(objJson);        
    }

    if (Global_Retorna_Permissoes('usuario', 'ver')){
        codigo_interno = codigo_interno + 1
        objJson = {
            key: codigo_interno,
            titulo: 'Usuários',
            caminho: '/usuarios/consulta',
            icone: <FaIcons.FaUsers/>,   
        };
        json.push(objJson);        
    }

    if (Global_Retorna_Permissoes('revenda', 'ver')){
        codigo_interno = codigo_interno + 1
        objJson = {
            key: codigo_interno, 
            titulo: 'Revendas',
            caminho: '/revendas/consulta',
            icone: <PiIcons.PiBuildingApartmentFill />,    
        };
        json.push(objJson);        
    } 

    //Início SubMenu das configuraões
    if (Global_Retorna_Permissoes('config_perm', 'ver')){
        codigo_interno = codigo_interno + 1
        objJson = {
            key: codigo_interno,
            titulo: 'Permissões',
            caminho: '/configuracoes/permissoes/consulta',
            icone: <IoIcons.IoIosArrowForward/>,                
        };
        json_Submenu_Configuracoes.push(objJson);        
    }
    
    if (json_Submenu_Configuracoes.length > 0){
        codigo_interno = codigo_interno + 1
        objJson = {
            key: codigo_interno, 
            titulo: 'Configurações',
            caminho: '#',
            icone: <Fa6Icons.FaGear/>,
            iconeFechado: <FaIcons.FaArrowAltCircleDown/>,
            iconeAberto: <FaIcons.FaArrowAltCircleUp/>,
            subMenu: json_Submenu_Configuracoes,               
        };
        json.push(objJson);         
    }
    //Fim SubMenu das configuraões
    return json;
};