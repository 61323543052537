import React, { Component } from "react";
import BarraLateral from "../../../componentes/BarraLateral";
import InputPadrao from "../../../componentes/Inputs/ImputPadrao";
import BotaoPadrao from "../../../componentes/Botoes/BotaoPadrao";
import TituloPagina from "../../../componentes/TituloPagina";
import MsgPopUpPadrao from "../../../componentes/PopUpModal/MsgPopUpPadrao";
import InputDataPadrao from "../../../componentes/Inputs/InputDataPadrao";
import TooglePadrao from "../../../componentes/Botoes/TooglePadrao";
import ComboBoxPadrao from "../../../componentes/ComboBox/ComboboxPadrão"
import TituloCadastroDetalhe from "../../../componentes/Titulos/TituloCadatrosDetalhe";
import Rodape from "../../../componentes/Rodape";
import { Global_Valida_Data, Global_Retorna_Permissoes, Global_Retorna_Usuario } from "../../../Funcoes_Globais"
import withRouter from "../../../Rotas/rotasParametros";
import Api from '../../../servicos/Axios';
import Estilos from "./estilo.module.css";
import PopUpLoadPadrao from '../../../componentes/PopUpLoad/PopUpLoadPadrao'

//https://www.freecodecamp.org/news/jwt-authentication-in-flask/

class UsuariosCadastro extends Component{    
    constructor(props){
        super(props)

        this.state = {
            State_MsgPopUpPadrao_Visivel: false,
            State_MsgPopUpPadrao_Mensagem: '',
            State_MsgPopUpPadrao_Tipo: 1,
            State_MsgPopUpPadrao_Acao: '',
            State_MsgPopUpPadrao_Mostrar_Botoes: true,

            State_LoadPopUpPadrao_Visivel: false,
            State_LoadPopUpPadrao_Mensagem: '', 
            
            State_Permissao_Editar: Global_Retorna_Permissoes('usuario', 'editar'),
            State_Permissao_Ver: Global_Retorna_Permissoes('usuario', 'ver'),
            State_Permissao_cadastrar: Global_Retorna_Permissoes('usuario', 'cadastrar'),
            State_Login_Revenda: Global_Retorna_Usuario('login_revenda'), 
            
            state_lista_revendas: [],
            state_lista_permissoes: [],
            state_lista_permissoes_filtrada: [],

            state_status_operacao: this.props.match.paramsLocation.state.status_operacao,
            state_usuario_id: this.props.match.paramsLocation.state.usuario_id,
            state_usuario_nome: '',
            state_usuario_email: '',
            state_usuario_senha: '',
            state_usuario_ativo: 'N',
            state_usuario_ativo_checkbox: false,
            state_usuario_data_cadastro: '',
            state_usuario_cod_revenda: 0,
            state_usuario_cod_permissao: 0,
        };

        this.Escolhe_Revenda = this.Escolhe_Revenda.bind(this);
        this.Escolhe_Permissao = this.Escolhe_Permissao.bind(this);
        
        //https://stackoverflow.com/questions/70143135/how-to-use-react-router-dom-v6-navigate-in-class-component
        //O Match pega o parâmetro passado de outra tela pelo link, o navegar navega entre as telas
        //console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>");
        //console.log(this.props.match)         
        //console.log(this.props.match.paramsLocation.state.usuario_id);      
        //console.log(this.props.match.paramsLocation.state.status_operacao);
        //console.log(this.state.state_cliente_id)

    };

    componentDidMount (){
        this.Funcoes_Iniciais();
    };

    Funcoes_Iniciais = async () =>{
        //Precisa esperar o retorno da função Retorna_Listar_Diversas
        await this.Retorna_Listar_Diversas();
        if (this.state.state_status_operacao === 'editar' || this.state.state_status_operacao === 'ver') {
            this.Pesquisar_Usuario();
        }        
    }

    Abre_MsgPopUpPadrao(Tipo = 1, Mostrar_botoes = true, Mensagem = '', Acao_Botao = '', Tempo_fechar = 0){
        this.setState({
            State_MsgPopUpPadrao_Visivel: true,
            State_MsgPopUpPadrao_Tipo: Tipo,
            State_MsgPopUpPadrao_Mensagem: Mensagem,
            State_MsgPopUpPadrao_Acao: Acao_Botao, 
            State_MsgPopUpPadrao_Mostrar_Botoes: Mostrar_botoes,  
        }, () => {
            if (Tempo_fechar > 0){
                setTimeout(
                    this.Fecha_MsgPopUpPadrao.bind(this, 'timer'),
                    Tempo_fechar
                );                
            };
        });
    };

    Fecha_MsgPopUpPadrao(Tipo_botao){
        this.setState({
            State_MsgPopUpPadrao_Visivel: false,               
        }, () => {
            if ((Tipo_botao === 'ok') || (Tipo_botao === 'sim') || (Tipo_botao === 'timer')){
                if (this.state.State_MsgPopUpPadrao_Acao === 'Teste'){
                    //console.log('Roda a Função Teste');
                };
            };
        });
    };
    
    Abre_LoadPopUpPadrao(mensagem){
        this.setState({
            State_LoadPopUpPadrao_Visivel: true,
            State_LoadPopUpPadrao_Mensagem: mensagem,            
        });
    };

    Fecha_LoadPopUpPadrao(){
        this.setState({
            State_LoadPopUpPadrao_Visivel: false,
            State_LoadPopUpPadrao_Mensagem: '',            
        });
    };
    
    Retorna_Listar_Diversas(){
        return new Promise((resolve) => {
            var objJson = { 
                revenda: true,
                permissao: true, 
            };
    
            this.Abre_LoadPopUpPadrao('Por favor aguarde ...');
            Api.Executa_chamada(
                this.props.navegar, 'Retorna_Listas_Diversas', 0, JSON.stringify(objJson)
            ).then((resposta) => {
                resolve(true)
                this.Fecha_LoadPopUpPadrao();
                if (resposta.status === 1){
                    //console.log('-*-*-*', resposta.dados.revendas[0].codigo);
                    this.setState({
                        state_lista_revendas: resposta.dados.revendas,
                        state_lista_permissoes: resposta.dados.permissoes,
                    }, function (){
                        if ((resposta.dados.revendas) && (this.state.State_Login_Revenda) && (this.state.state_status_operacao === 'cadastrar')){
                            this.Filtra_Permissao(resposta.dados.revendas[0].codigo)
                        }
                    });
                }else if (resposta.status === 2){
                    this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)           
                }
                else{
                    this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
                };           
            }); 
        });       
    };  
    
    Escolhe_Revenda(e){
        e.preventDefault();      
        this.setState({
            state_usuario_cod_revenda: e.target.value,
            state_usuario_cod_permissao: 0,
            state_lista_permissoes_filtrada: this.state.state_lista_permissoes,
        }, function(){
            this.Filtra_Permissao(this.state.state_usuario_cod_revenda);
        }
    );
    };
    
    Filtra_Permissao(id_revenda = 0){
        const newList = this.state.state_lista_permissoes.filter((item) => item.cod_revenda === Number(id_revenda));
        this.setState({
            state_lista_permissoes_filtrada: newList,
        }, function (){
            //
        });         
    }

    Escolhe_Permissao(e){
        e.preventDefault();
        this.setState({
            state_usuario_cod_permissao:  e.target.value
        });
    }       
    
    Pesquisar_Usuario(){
        var objJson = { 
            Pesq_Codigo: this.state.state_usuario_id,
        };

        this.Abre_LoadPopUpPadrao('Por favor aguarde ...');
        Api.Executa_chamada(
            this.props.navegar, 'Usuario_Detalhe', 0, JSON.stringify(objJson)
        ).then((resposta) => {
            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                //console.log(new Date(resposta.dados.data_cadastro).toLocaleDateString("en-CA"));
                //console.log(new Date(resposta.dados.data_cadastro));
                this.setState({
                    state_usuario_id: resposta.dados.usuario.codigo,                    
                    state_usuario_nome: resposta.dados.usuario.nome,
                    state_usuario_email: resposta.dados.usuario.email,
                    state_usuario_ativo: resposta.dados.usuario.ativo,
                    state_usuario_data_cadastro: new Date(resposta.dados.usuario.data_cadastro).toLocaleDateString("en-CA"),
                    state_usuario_cod_revenda: resposta.dados.usuario.codigo_revenda,
                    state_usuario_cod_permissao: resposta.dados.usuario.cod_permissao,
                }, function (){
                    this.Filtra_Permissao(this.state.state_usuario_cod_revenda);
                });
            }else if (resposta.status === 2){
                this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)           
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });        
    };

    Salva_Usuario(){
        if (this.state.state_status_operacao === 'cadastrar'){
            this.Cadastra_usuario();
        }else{
            this.Atualiza_Usuario();
        };
    }

    Cadastra_usuario(){
        //Faz as validações dos campos 
        var objUsuario = { 
            usuario_id: this.state.state_usuario_id,
            usuario_nome: this.state.state_usuario_nome,
            usuario_email: this.state.state_usuario_email,
            usuario_senha: this.state.state_usuario_senha,
            usuario_ativo: this.state.state_usuario_ativo,
            usuario_cod_revenda: this.state.state_usuario_cod_revenda,
            usuario_cod_permissao: this.state.state_usuario_cod_permissao,            
        };                   

        this.Abre_LoadPopUpPadrao('Por favor aguarde ...');
        Api.Executa_chamada(
            this.props.navegar, 'Usuario_Cadastra', 0, JSON.stringify(objUsuario)
        ).then((resposta) => {
            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                this.setState({
                    state_usuario_id: resposta.dados.usuario_id,
                    state_status_operacao: 'editar',
                }, ()=>{
                    this.Pesquisar_Usuario();
                    this.Abre_MsgPopUpPadrao(1, false, 'Usuário cadastrado com sucesso!', '', 2000);
                });
            }else if (resposta.status === 2){
                this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)           
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });        
    }

    Atualiza_Usuario(){
        //Faz as validações dos campos
        var objUsuario = { 
            usuario_id: this.state.state_usuario_id,
            usuario_nome: this.state.state_usuario_nome,
            usuario_email: this.state.state_usuario_email,
            usuario_ativo: this.state.state_usuario_ativo,
            usuario_cod_revenda: this.state.state_usuario_cod_revenda,
            usuario_cod_permissao: this.state.state_usuario_cod_permissao,
        };               

        this.Abre_LoadPopUpPadrao('Por favor aguarde ...');
        Api.Executa_chamada(
            this.props.navegar,'Usuario_Atualiza', 0, JSON.stringify(objUsuario)
        ).then((resposta) => {
            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                this.setState({
                    state_usuario_id: resposta.dados.usuario_id,
                }, ()=>{
                    this.Pesquisar_Usuario();
                    this.Abre_MsgPopUpPadrao(1, false, 'Usuário Atualizado com sucesso!', '', 2000);
                });
            }else if (resposta.status === 2){
                this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)           
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });        
    }
    
    ChkBloqueadoChange(){
        this.setState({state_cliente_bloqueado_checkbox: ''})    
    };

    Atualiza_Validade_Licensa(data_str){
        if (Global_Valida_Data(data_str)){
            this.setState({state_cliente_validade_licenca: data_str});
        }
    };

    render(){
        return(
        <div>
            <MsgPopUpPadrao
                visivel = {this.state.State_MsgPopUpPadrao_Visivel}
                mensagem = {this.state.State_MsgPopUpPadrao_Mensagem}
                tipo = {this.state.State_MsgPopUpPadrao_Tipo}
                mostrar_botoes = {this.state.State_MsgPopUpPadrao_Mostrar_Botoes}
                onclick_btn_ok = {this.Fecha_MsgPopUpPadrao.bind(this, 'ok')}
                onclick_btn_sim = {this.Fecha_MsgPopUpPadrao.bind(this, 'sim')}
                onclick_btn_nao = {this.Fecha_MsgPopUpPadrao.bind(this, 'nao')}
            />
            <PopUpLoadPadrao
                visivel = {this.state.State_LoadPopUpPadrao_Visivel}
                mensagem = {this.state.State_LoadPopUpPadrao_Mensagem}
            />                                 
            <BarraLateral/>
            <div className="GlobalConteinerPrincipal"> 
                <div className="GlobalConteudoPrincipal">

                    <TituloPagina titulo={"Usuario > Cadastro"} /> 
                    <TituloCadastroDetalhe titulo={"Geral"} />
                    <div className={Estilos.ContainerGrid}>
                        <InputDataPadrao 
                            titulo = 'Dt. Cadastro'
                            value={this.state.state_usuario_data_cadastro}
                            //onChange={valor => this.Atualiza_Validade_Licensa(valor.target.value)}    
                            disabled = {true}
                        /> 
                        <InputPadrao 
                                titulo={'Email'}
                                onChange = {valor => this.setState({state_usuario_email: valor.target.value})}
                                value = {this.state.state_usuario_email}    
                        />                                                
                        <InputPadrao 
                                titulo={'Nome'}
                                onChange = {valor => this.setState({state_usuario_nome: valor.target.value})}
                                value = {this.state.state_usuario_nome}    
                        />  

                        {
                            (this.state.state_status_operacao === 'cadastrar') ?
                            <InputPadrao 
                                titulo={'Senha'}
                                onChange = {valor => this.setState({state_usuario_senha: valor.target.value})}
                                value = {this.state.state_usuario_senha}
                                disabled = {(this.state.state_status_operacao === 'editar' ? true : false)}    
                            />:
                            null
                        }
                        {
                            this.state.State_Login_Revenda === false ?
                            <ComboBoxPadrao
                                titulo = {'Revenda'}
                                dica = {'Escolha a Revenda'}
                                itens = {this.state.state_lista_revendas}
                                onChange = {this.Escolhe_Revenda}
                                select_value = {this.state.state_usuario_cod_revenda}
                            />                            
                            : null
                        }
                        <ComboBoxPadrao
                            titulo = {'Permissão'}
                            dica = {'Escolha a Permissão'}
                            itens = {this.state.state_lista_permissoes_filtrada}
                            onChange = {this.Escolhe_Permissao}
                            select_value = {this.state.state_usuario_cod_permissao}
                        />
                        <TooglePadrao
                                descricao={'Ativo'}
                                marcado={this.state.state_usuario_ativo}
                                onClick={() => this.setState({state_usuario_ativo: (this.state.state_usuario_ativo === 'S') ? 'N' : 'S'})}
                        />                                                                                                                                              
                    </div>                                                                                                                         

                    <div className={Estilos.ConteinerBotoes} >

                        <BotaoPadrao                                           
                            tipo={"voltar"} 
                            descricao={"Voltar"} 
                            onClick={() =>{this.props.navegar("/usuarios/consulta")}} 
                        />

                        {
                            ((this.state.State_Permissao_Editar || this.state.State_Permissao_cadastrar) && (this.state.state_status_operacao === 'editar' || this.state.state_status_operacao === 'cadastrar')) ?
                            <BotaoPadrao                   
                                tipo={"salvar"} 
                                descricao={"Salvar"} 
                                onClick={() =>{this.Salva_Usuario()}} 
                                //onClick={() =>{this.Retorna_Listar_Diversas()}}
                            /> 
                            : null                            
                        }                         
                    </div>                                                                                                   
                </div>
            </div>
            <Rodape/>
        </div>            
        );
    };
};

export default withRouter(UsuariosCadastro);