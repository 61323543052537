import { FaCheck } from "react-icons/fa";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { CoresGlobal } from "../../../estilos"

import Estilo from "./estilo.module.css";
import React from "react";

const TogglePadrao = ({descricao, marcado, estilo, onClick}) =>{          
    return(
        <div 
            onClick={onClick}
            className={Estilo.ToggleContainer} 
            style={{backgroundColor: '#fff', border: `solid 0.1em ${CoresGlobal.cor_paleta_6}`}}
        >
            <div className={Estilo.Indicador} style={{background: (marcado === 'S') ? CoresGlobal.cor_paleta_4 : '#fff'}}>
                {
                    marcado ? <FaCheck /> : <MdCheckBoxOutlineBlank/>
                }
            </div>
            <div className={Estilo.Descricao} style={{color: CoresGlobal.cor_paleta_1}}>
                {descricao}
            </div>
        </div> 
    );
};

export default TogglePadrao;